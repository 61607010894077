<ng-container *ngIf="!mobile">
	<div class="row text-white m-4 mb-0 justify-content-between">
		<app-icon-card
			*ngFor="let card of item"
			[picture]="card.picture"
			[title]="card.title"
			[description]="card.description"
			class="col-2">
		</app-icon-card>
	</div>
	<div class="row text-center align-items-center bg-white figure-list">
		<h1 *ngFor="let card of item" class="col m-0">{{card.number}}</h1>
	</div>
</ng-container>
<ng-container *ngIf="mobile">
	<div class="m-4">
		<div *ngFor="let card of item" class="d-flex align-items-center">
			<div
				class="bg-white align-items-center d-flex justify-content-center rounded-circle figure-list">
				<h1 class="m-0"> {{card.number}}</h1>
			</div>
			<app-icon-card
				[picture]="card.picture"
				[title]="card.title"
				[description]="card.description"
				class="col text-white">
			</app-icon-card>
		</div>
	</div>
</ng-container>
