<div class="flex-column p-2">
	<div class="d-flex justify-content-center">
		<img class="picture" src="assets/batiment.svg"/>
	</div>
	<div class="d-flex flex-column">
		<h1> Guitare</h1>
		<p> Description</p>
		<div class="bg-info w-100 m-2"></div>
		<div class="d-flex align-items-baseline">
			<h1>9</h1>
			<h2>,99</h2>
			<h2>€</h2>
		</div>
	</div>
</div>
